<template>
  <div class="card mb-3">
    <div
      class="card-header border-0 cursor-pointer"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#kt_account_profile_details"
      aria-expanded="true"
      aria-controls="kt_account_profile_details"
    >
      <!--begin::Card title-->
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Themes</h3>
      </div>
      <!--end::Card title-->
    </div>
    <div id="kt_account_profile_details" class="collapse show">
      <div class="card-body mb-0  pb-0">
        <BaseForm :validate="{}" :submitting="submitting" v-on:form-submit="onSave">
          <div class="row mb-6">
            <!--begin::Label-->
            <!--end::Label--><!--begin::Col-->
            <div class="col-lg-12 fv-row">
              <template v-for="(item, idx) in BACKGROUNDS " v-bind:key="idx">
                <img :src="item" width="80" height="80" alt="item"
                     style="margin: 0 0 10px 10px">
                <label class="form-check-sm">
                  <input type="radio" name="background" class="form-check-input" :value="item"
                         v-model="model.background"
                         @change="onSelectBackground">
                </label>
              </template>

            </div>
            <div class="row mb-6">
              <label class="col-lg-3 col-form-label fw-bold fs-6">Upload Background:</label>
              <div class="col-lg-9 fv-row">
                <ImageUpload v-model="model.background"/>
              </div>
            </div>
            <div class="row mb-6">
              <label class="col-lg-3 col-form-label fw-bold fs-6">No Background:</label>
              <div class="col-lg-9 fv-row">
                <input type="radio" name="background" class="form-check-input" :value="``"
                       v-model="model.background"
                       @change="onSelectBackground">
              </div>
            </div>
            <!--end::Col-->
          </div>
        </BaseForm>
      </div>
    </div>
  </div>
  <div class="card mb-5 mb-xl-10">
    <div
      class="card-header border-0 cursor-pointer"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#kt_account_profile_details"
      aria-expanded="true"
      aria-controls="kt_account_profile_details"
    >
      <!--begin::Card title-->
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Theme </h3>
      </div>
      <!--end::Card title-->
    </div>
    <div class="card-body">
      <div class="row mb-6">
        <label class="col-lg-3 col-form-label fw-bold fs-6">Theme:</label>
        <div class="col-lg-9 fv-row">
          <BaseRadio v-model="themeModel" :data="THEME_DEFAULT" name="theme" v-on:changeValue="onSelectTheme"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, ref, watch} from "vue";
import store from "@/store";
import UserService from "@/core/services/UserService";
import {Mutations} from "@/store/enums/StoreEnums";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {BACKGROUNDS, THEME_DEFAULT} from "@/core/config/Constant";
import {DATE_FORMAT} from "@/views/user/profile/Settings";
import BaseForm from "@/components/base/form/BaseForm";
import ImageUpload from "@/components/base/upload/ImageUpload";
import BaseRadio from "@/components/base/form/BaseRadio";

export default {
  name: "Background",
  components: {BaseRadio, ImageUpload, BaseForm},
  setup() {
    const theme = computed(() => store.state.UserModule.theme);

    const submitting = ref(false);
    const profile = computed(() => store.getters.currentUser)
    const themeModel = ref(theme.value);

    watch(() => theme.value, (cb) => {
      themeModel.value = cb;
    })

    const model = ref({
        avatar: profile.value?.avatar,
        firstName: profile.value?.firstName,
        lastName: profile.value?.lastName,
        phone: profile.value?.phone,
        dateFormat: profile.value?.dateFormat,
        background: profile.value?.background,
      }
    )
    watch(profile, (cb) => {
      model.value.avatar = cb.avatar;
      model.value.firstName = cb.firstName;
      model.value.lastName = cb.lastName;
      model.value.phone = cb.phone;
      model.value.dateFormat = cb.dateFormat;
      model.value.background = cb.background;
    })

    const onSave = async () => {
      submitting.value = true;
      await UserService.updateProfile(model.value).then(res => {
        store.commit(Mutations.SET_PROFILE, res)

      }).finally(() => {
        submitting.value = false;
      })
    }

    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("Theme", [])
    })
    return {
      model,
      submitting,
      onSave,
      DATE_FORMAT,
      BACKGROUNDS,
      themeModel,
      THEME_DEFAULT,
    }
  },
  methods: {
    onSelectBackground(e) {
      store.commit('SET_BACKGROUND', e.target.value)
    },
    onSelectTheme(value) {
      store.dispatch('PRESET_THEME', value);
    }
  }
}
</script>

<style scoped>

</style>